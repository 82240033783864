<template>
  <form @submit="login" :validation-schema="formSchema" class="py-2">
    <FormField v-slot="{ componentField }" name="email">
      <FormItem v-auto-animate>
        <!-- <FormLabel>Email</FormLabel> -->
        <FormControl>
          <Input ref="username" v-bind="componentField" :disabled="loading" autocomplete="email" type="text"
            placeholder="Email" />
        </FormControl>
        <FormMessage class="font-light ml-3" />
      </FormItem>
    </FormField>

    <FormField v-slot="{ componentField }" name="password">
      <FormItem v-auto-animate class="py-2">
        <!-- <FormLabel>Password</FormLabel> -->
        <FormControl>
          <Input v-bind="componentField" :disabled="loading" autocomplete="current-password"
            :type="showPassword ? 'text' : 'password'" placeholder="Password" />
          <button type="button" @click.stop="() => { showPassword = !showPassword }"
            class="absolute end-0 top-0 min-h-9 items-center" variant="outline" size="icon">
            <span class=" inset-y-0 px-2">
              <icon :name="showPassword ? 'lucide:eye' : 'lucide:eye-off'"
                class="w-6 h-4 text-muted-foreground" />
            </span>
          </button>
        </FormControl>
        <FormMessage class="font-light ml-3" />
      </FormItem>
    </FormField>

    <div class="grid grid-cols-1 gap-4 py-3">
      <nuxt-link to="/forgot-password" class="no-underline pb-4">
        <span type="" class="font-medium text-muted-foreground hover:underline underline-offset-4 text-xs">
          Forgot your password?
        </span>
      </nuxt-link>

      <Button type="submit" class="text-[1em] squircle-10" :disabled="errors.email || errors.password">
        <Loader2 v-if="loading && usingEmailPassword" class="w-4 h-4 mr-2 animate-spin" />
        Login
      </Button>
      
      <SocialLogin />

      
    </div>
  </form>
</template>

<script setup lang="ts">
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import * as z from 'zod'
import { Button } from '@/components/ui/button'
import { Loader2 } from 'lucide-vue-next'
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useFocus } from '@vueuse/core'
import SocialLogin from './SocialLogin.vue'
import { vAutoAnimate } from '@formkit/auto-animate'
const username = ref()
const { focused } = useFocus(username, { initialValue: true })

const client = useSupabaseClient()
const loading = ref(false)
const usingEmailPassword = ref(false)
const showPassword = ref(false)

const formSchema = toTypedSchema(z.object({
  email: z.string().email().optional(),
  password: z.string().min(8).optional(),
}).transform((o) => ({
  email: o.email,
  password: o.password
})))

const apiErrors = ref({
  wrongCredentials: false,
  Provider: false,
  accountNotVerified: false,
  accountSuspended: false
})

const apiErrorMessages = computed(() => {
  return {
    email: apiErrors.value.wrongCredentials ? 'Incorrect username or password' : apiErrors.value.accountSuspended ? 'Account suspended' : '',
    password: apiErrors.value.accountNotVerified ? 'Account not verified' : ''
    // Add more here as needed
  };
});

const { validate, errors, resetForm, handleSubmit, setErrors, meta: formMeta } = useForm({
  validationSchema: formSchema
})

const route = useRoute()

const login = handleSubmit(async (values) => {
  usingEmailPassword.value = true
  loading.value = true
  const validator = await validate();
  if (validator.valid) {
    const { error } = await client.auth.signInWithPassword({
      email: values.email,
      password: values.password
    })
    loading.value = false
    if (error) {
      apiErrors.value.wrongCredentials = error.message === "Invalid login credentials"
      // apiErrors.value.accountNotVerified = error.message === 'account-not-verified'
      // apiErrors.value.accountSuspended = error.message === 'account-suspended'
    }
    // Set the error messages
    if (apiErrors.value.wrongCredentials || apiErrors.value.accountNotVerified || apiErrors.value.accountSuspended) {
      setErrors({
        email: apiErrorMessages.value.email,
        password: apiErrorMessages.value.password
      })
    }
  }
})

</script>